import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import CabinsFullWidth from "../components/cabinsFullWidth"
import GettingHereComponent from "../components/gettingHere"

const GettingHere = () => {
  return (
    <Layout>
       <SEO title="Gallery" />
      <GettingHereComponent />
      <CabinsFullWidth />
    </Layout>
  )
}

export default GettingHere
