import React from 'react'
import styled from "styled-components"
import PageHeader from '../elements/pageHeader'
import backgroundImageSand from "../assets/images/sand_texture-bg.jpg"
import BasicParagraph from '../elements/basicParagraph'
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"


const GettingHere = () => {

  const images = useStaticQuery(graphql`
     query {
      pacificCostal: file(name: {eq: "pacificCostal-logo"}) {
         childImageSharp {
           fluid {
             ...GatsbyImageSharpFluid
           }
         }
       }
      budget: file(name: {eq: "budget_logo"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tofinoBus: file(name: {eq: "tb-logo-2"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bcFerries: file(name: {eq: "bc-ferries"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clipper: file(name: {eq: "clipper-logo"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blackball: file(name: {eq: "blackball-logo"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kdair: file(name: {eq: "kd-air"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
     }
   `)


  return (
    <>
    <PageHeader header="Getting Here" />
      <Container>        
        <InfoWrapper>
          <BasicParagraph 
            title="How to get here" 
            info="Vancouver Island and Gold Coast Retreat are accessible by both ferry 
            (to Victoria and Nanaimo) and airplane (direct to Tofino, Comox, Victoria and Nanaimo). 
            Ferries depart from Washington State, Tsawwassen (South of Vancouver), and Horseshoe Bay 
            (North of Vancouver). Advance ferry reservations are highly reccommended on all routes during 
            weekends, holiday periods and summer months." />

          <BasicParagraph 
            title=""
            info="Whether driving or taking a bus, the road from Nanaimo is one of the most spectacular 
            in the world. You will pass through the towering old-growth forests of Cathedral Grove, the 
            township of Port Alberni, majestic Sutton Pass, and through the Pacific Rim National Park Reserve. 
            Please note Route 4/Pacific Rim Highway is a designated Winter Tire & Chain-up Route, making 
            M&S rated tires mandatory for all vehicles and chains mandatory for commercial vehicles from 
            OCTOBER 1-MARCH 31." />

            <BasicParagraph 
            title=""
            info="Once you have driven across Vancouver Island to the Tofino/Ucluelet junction, 
            you are 28 km (17 miles) away from Gold Coast Retreat. While driving you'll pass through 
            the Pacific Rim National Park Reserve. Continue driving towards Tofino. The Retreat is 5km 
            or 2.5mi before the town of Tofino. Once you pass The visitors info center on your right, 
            take a left at the first public road, South Chesterman Beach Road. Go approximately 10 
            driveways on the right and you will see us!" />

        </InfoWrapper>
        <BusinessesWrapper>
          <BusinessCard>
            <h4>Flying</h4>
            <p>There is 1 airline who makes the trip from Vancouver 
              International (YVR), South Terminal to the Tofino airport. </p>
            <a
              href="https://www.pacificcoastal.com/tofino/" 
              target="example"
              rel="norefferer">
              <Image 
              fluid={images.pacificCostal.childImageSharp.fluid} />
            </a>
          </BusinessCard>
          <BusinessCard>
             <h4>Driving</h4>
              <p> Whether renting a car or taking the bus, highway 4 is breathtaking. 
              Check the links below and get yourself here.  </p>
              <a
                href="https://www.budget.ca/en/home" 
                target="example"
                rel="norefferer">
                <Image 
                fluid={images.budget.childImageSharp.fluid} />
              </a>
              <a
                href="https://tofinobus.ca/" 
                target="example"
                rel="norefferer">
                <Image 
                fluid={images.tofinoBus.childImageSharp.fluid} />
              </a>
          </BusinessCard>
          <BusinessCard>
             <h4>Ferries</h4>
              <p>If you are travelling overland from the mainland you will need to 
              use one of the many ferries available to reach Vancouver Island. Check the 
              links below for more information.  </p>
              <a
                href="https://www.bcferries.com/" 
                target="example"
                rel="norefferer">
                <Image fluid={images.bcFerries.childImageSharp.fluid} />
              </a>
             <a href="https://www.clippervacations.com/" 
                target="example"
                rel="norefferer">
                <Image fluid={images.clipper.childImageSharp.fluid} />
              </a>             
              <a
                href="https://www.cohoferry.com/" 
                target="example"
                rel="norefferer">
                  <Image fluid={images.blackball.childImageSharp.fluid} />
              </a>
          </BusinessCard>
        </BusinessesWrapper>        
      </Container>
    </>
  )
}

export default GettingHere

const Container = styled.div`
  padding: 5rem calc((100vw - 1300px) / 2);
  background: #fff;
  color: #000;
  background-image: url(${backgroundImageSand});
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 2fr 1fr;
  padding-top: 0;
  
  @media screen and (max-width: 868px){
    grid-template-columns: 1fr;
    min-height: 500px;
  }
`
const InfoWrapper = styled.div`
  padding: 1rem;
  
`

const BusinessesWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`

const Image = styled(Img)`
  width: 200px;
  margin-bottom: 1rem;
`

const BusinessCard = styled.div`
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: left;

  a {
    margin-bottom: 1rem;
  }
`