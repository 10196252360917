import React from 'react'
import styled from "styled-components"

const BasicParagraph = ({title, info}) => {
  return (
    <ParagraphContainer>
      <Header>
        {title}
      </Header>
      <MainContainer>
        {info}
      </MainContainer>
    </ParagraphContainer>
  )
}

export default BasicParagraph

const ParagraphContainer = styled.div`
  font-size: clamp(1.2rem, 5vw, 1.75rem);
  text-align: center;
  margin-bottom: 3rem;
  color: #000;
  text-transform: uppercase;
`
const Header = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: left;
`

const MainContainer = styled.p`
  font-size: 1rem;
  text-align: left;
`